import { getPosition } from "./meta-settings.js";
import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";

AOS.init({
	startEvent: "load",
	disableMutationObserver: false,
	duration: 600,
	once: true,
});
AOS.refresh(true);

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

// We listen to the resize event
window.addEventListener("resize", () => {
	// We execute the same script as before
	vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);
	document.documentElement.style.setProperty(
		"--header-height",
		`${
			document.getElementsByClassName("header")[0].getBoundingClientRect()
				.height
		}px`
	);
});

const marqueLine = document.getElementsByClassName("marquee-content")[0];
if (marqueLine) {
	const $mq = $(".marquee-content").marquee({
		duration: 90000,
		duplicated: true,
		pauseOnHover: true,
		delayBeforeStart: 100,
		startVisible: true,
	});

	if (window.innerWidth <= 1024) {
		marqueLine.addEventListener("click", () => {
			$mq.marquee("toggle");
		});
	}
}

const header = document.getElementsByClassName(`header`)[0];
// Header scroll
const scrollContainer = () => {
	return window.pageYOffset !== undefined
		? window.pageYOffset
		: (document.documentElement || document.body.parentNode || document.body)
				.scrollTop;
};

if (header && header.classList.contains("fixed")) {
	const handleScroll = () => {
		if (scrollContainer() > 0) {
			header.classList.add('scrolled');
		} else {
			header.classList.remove('scrolled');
		}
	};

	document.addEventListener('scroll', handleScroll);

	window.addEventListener('load', handleScroll);
}

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
	document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
	$(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;

$(".dropdown-toggle").click(function (e) {
	if (isMobileMenuEnable) {
		//close all opened sub menu
		$(".menu-item.dropdown.active .dropdown-menu").slideUp({
			complete: function () {
				$(this).closest(".dropdown").removeClass("active");
			},
		});

		//open current submenu
		$(this).closest(".menu-item.dropdown").toggleClass("active");
		if ($(this).closest(".menu-item.dropdown").hasClass("active")) {
			e.preventDefault();
			$(this).next(".dropdown-menu").slideDown();
		}
	}
});

// toggle menu handler
function menuToggle() {
	$(".menu-toggle").toggleClass("active");
	$(".navbar-nav").toggleClass("active");
	$(".header-close-wrapper").toggleClass("active");
	// LockScroll when burger open and enable when closed and enable scroll on menu
	scrollLock.getScrollState()
		? scrollLock.disablePageScroll(document.querySelector(".navbar-nav .menu"))
		: scrollLock.enablePageScroll();
}
//menu update function
function updateMenu() {
	isMobileMenuEnable =
		$(window).outerWidth() <= mobileMenuStartPoint ||
		$(".mobile-header").length;
	if (!isMobileMenuEnable) {
		$(".dropdown-menu").css("display", "");
		$(".header-close-wrapper").removeClass("active");
		$(".menu-item.active").removeClass("active");
		$(".navbar-nav").removeClass("active");
		$(".menu-toggle").removeClass("active");
		// LockScroll when burger open and enable when closed
		scrollLock.enablePageScroll();
	}
}
$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

// Приклад приєднання lottie на проекті (BOLD треба приєднювати тепер просто .gif)
// lottie.loadAnimation({
//   container: document.getElementById(``),
//   renderer: "svg",
//   loop: true,
//   autoplay: true,
//   path: "./js/lottie/name.json",
// });
// test

var elements = document.querySelectorAll(".force-sticky");
Stickyfill.forceSticky();
Stickyfill.add(elements);

const scrollToggle = document.getElementsByClassName("scroll-toggle")[0];
if (scrollToggle) {
	scrollToggle.addEventListener("mouseenter", () => {
		scrollToggle.classList.add("active");
	});
	scrollToggle.addEventListener("mouseleave", () => {
		scrollToggle.classList.remove("active");
	});
}

function parallax(e) {
	const elements = document.querySelectorAll(".decor-parallax");

	elements.forEach((elem) => {
		const speed = elem.getAttribute("data-speed");
		const rect = elem.getBoundingClientRect();

		if (rect.top < window.innerHeight && rect.bottom > 0) {
			const x = (window.innerWidth - e.pageX * speed) / 400;
			const y = (window.innerHeight - e.pageY * speed) / 400;

			elem.style.transform = `translateX(${x}px) translateY(${y}px)`;
		}
	});
}

if (
	window.innerWidth >= 1025 &&
	document.getElementsByClassName("decor-parallax")[0]
) {
	document.addEventListener("mousemove", parallax);
}

const videoPlayer = document.getElementsByClassName("video-player")[0];
if (videoPlayer) {
	const videoPlayersArr = document.querySelectorAll(".video-player");
	for (let i = 0; i < videoPlayersArr.length; i++) {
		const player = new Plyr(videoPlayersArr[i]);

		if (window.innerWidth >= 1024) {
			setTimeout(function () {
				const plyrArr = document.querySelectorAll(".plyr");
				for (let j = 0; j < plyrArr.length; j++) {
					plyrArr[i].classList.add("plyr-hide-controls");
				}

				plyrArr[i].addEventListener("mouseenter", () => {
					plyrArr[i].classList.remove("plyr-hide-controls");
				});
			}, 300);
		}
	}
}

const sectorsSlider = document.getElementsByClassName("sectors-slider")[0];

if (sectorsSlider) {
	const sectorsCardsArr = sectorsSlider.querySelectorAll(".swiper-slide");

	if (sectorsCardsArr.length > 2) {
		const sectorsSwiper = new Swiper(".sectors-slider", {
			slidesPerView: 1,

			spaceBetween: 20,
			threshold: 10,
			autoplay: {
				delay: 3000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			navigation: {
				prevEl: ".sectors-slider-buttons.slider-buttons .slider-btn-prev",
				nextEl: ".sectors-slider-buttons.slider-buttons .slider-btn-next",
			},
			breakpoints: {
				552: {
					slidesPerView: 1.5,
					spaceBetween: 20,
				},

				852: {
					slidesPerView: 2,
					spaceBetween: 30,
				},

				1100: {
					slidesPerView: 2,
					spaceBetween: 56,
				},
			},
		});
	}
}

const jobsSlider = document.getElementsByClassName("jobs-slider")[0];

if (jobsSlider) {
	const jobsSlidesArr = jobsSlider.querySelectorAll('.swiper-slide');
	let loop = false;
	let loopedSlides = false;
	if(jobsSlidesArr.length > 3) {
		loop = true;
		loopedSlides = jobsSlidesArr.length * 3;
	}



	const jobsSwiper = new Swiper(".jobs-slider", {
		slidesPerView: 1.05,
		observer: true,
		loop: loop,
		loopedSlides: loopedSlides,
		spaceBetween: 8,
		threshold: 10,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		navigation: {
			prevEl: ".jobs-slider-buttons.slider-buttons .slider-btn-prev",
			nextEl: ".jobs-slider-buttons.slider-buttons .slider-btn-next",
		},
		breakpoints: {
			452: {
				slidesPerView: 1.6,
			},

			652: {
				slidesPerView: 2,
				spaceBetween: 8,
			},

			768: {
				slidesPerView: 2,
				spaceBetween: 20,
			},

			1024: {
				slidesPerView: 2,
				spaceBetween: 30,
			},
		},
	});
}

const expertiseSlider = document.getElementsByClassName("expertise-slider")[0];

if (expertiseSlider) {
	const expertiseSwiper = new Swiper(".expertise-slider", {
		slidesPerView: 1,
		spaceBetween: 20,
		threshold: 10,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		navigation: {
			prevEl: ".expertise-slider-buttons.slider-buttons .slider-btn-prev",
			nextEl: ".expertise-slider-buttons.slider-buttons .slider-btn-next",
		},
	});
}

const historySlider = document.getElementsByClassName("history-slider")[0];

if (historySlider) {
	const historySwiper = new Swiper(".history-slider", {
		slidesPerView: 1,
		spaceBetween: 0,
		threshold: 10,
		speed: 600,
		autoplay: {
			delay: 3500,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		effect: "creative",
		creativeEffect: {
			limitProgress: 8,
			prev: {
				translate: [0, 0, -320],
				opacity: 0,
			},
			next: {
				effect: "scale",
				scale: 0.79,
				translate: ["calc(100% - 17%)", 0, 1],
				opacity: 1,
			},
		},
		navigation: {
			prevEl: ".history-slider-buttons.slider-buttons .slider-btn-prev",
			nextEl: ".history-slider-buttons.slider-buttons .slider-btn-next",
		},
	});
}

const benefitsSlider = document.getElementsByClassName("benefits-slider")[0];
if (benefitsSlider) {
	const benefitsSwiper = new Swiper(".benefits-slider", {
		slidesPerView: 2,
		spaceBetween: 20,
		threshold: 10,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		navigation: {
			prevEl: ".benefits-slider-buttons.slider-buttons .slider-btn-prev",
			nextEl: ".benefits-slider-buttons.slider-buttons .slider-btn-next",
		},
		breakpoints: {
			652: {
				slidesPerView: 3,
			},
			900: {
				slidesPerView: 4,
			},
			1025: {
				slidesPerView: 5,
			},
		},
	});
}

const teamsSlider = document.getElementsByClassName("teams-slider")[0];
if (teamsSlider) {
	const teamsSwiper = new Swiper(".teams-slider", {
		slidesPerView: 1,
		spaceBetween: 18,
		threshold: 10,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		navigation: {
			prevEl: ".teams-slider-buttons.slider-buttons .slider-btn-prev",
			nextEl: ".teams-slider-buttons.slider-buttons .slider-btn-next",
		},
		breakpoints: {
			376: {
				slidesPerView: 1.3,
				spaceBetween: 20,
			},
			552: {
				slidesPerView: 2,
				spaceBetween: 20,
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 24,
			},
			1200: {
				slidesPerView: 4,
				spaceBetween: 32,
			},
		},
	});
}

const hiringSlider = document.getElementsByClassName("hiring-slider")[0];
if (hiringSlider) {
	const hiringSlidesArr = hiringSlider.querySelectorAll(".swiper-slide");
	const hiringContent = document.getElementsByClassName(
		"hiring-section__content"
	)[0];

	if (hiringSlidesArr.length > 3 || window.innerWidth <= 768) {
		hiringContent.style.height = "auto";

		const hiringSwiper = new Swiper(".hiring-slider", {
			slidesPerView: 1,
			spaceBetween: 32,
			threshold: 10,
			autoplay: {
				delay: 3500,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			navigation: {
				prevEl: ".hiring-slider-buttons.slider-buttons .slider-btn-prev",
				nextEl: ".hiring-slider-buttons.slider-buttons .slider-btn-next",
			},
			breakpoints: {
				552: {
					slidesPerView: 2,
					spaceBetween: 30,
				},
			},
		});
	}
}

const ourSectorsSlider =
	document.getElementsByClassName("our-sectors-slider")[0];
if (ourSectorsSlider) {
	const ourSectorsArr = ourSectorsSlider.querySelectorAll(".swiper-slide");
	if (ourSectorsArr.length > 2) {
		const ourSectorsSwiper = new Swiper(".our-sectors-slider", {
			slidesPerView: 1,
			spaceBetween: 20,
			threshold: 10,
			autoplay: {
				delay: 3500,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			navigation: {
				prevEl: ".our-sectors-slider-buttons.slider-buttons .slider-btn-prev",
				nextEl: ".our-sectors-slider-buttons.slider-buttons .slider-btn-next",
			},
			breakpoints: {
				768: {
					slidesPerView: 2,
					spaceBetween: 32,
				},
			},
		});
	}
}

const testimonialsSlider = document.getElementsByClassName(
	"testimonials-slider"
)[0];

if (testimonialsSlider) {
	const decorTestimonialsSwiper = new Swiper(".decor-testimonials-slider", {
		slidesPerView: 1,
		spaceBetween: 0,
		threshold: 10,
		speed: 600,
		effect: "creative",
		grabCursor: true,
		creativeEffect: {
			limitProgress: 3,
			prev: {
				translate: ["-88%", 0, 0],
				rotate: [0, 50, 0],
				scale: 0.9,
			},
			next: {
				translate: ["88%", 0, 0],
				rotate: [0, -50, 0],
				scale: 0.9,
			},
		},
		breakpoints: {
			768: {
				creativeEffect: {
					limitProgress: 3,
					prev: {
						translate: ["-93%", 0, 0],
						rotate: [0, 50, 0],
						scale: 0.85,
					},
					next: {
						translate: ["93%", 0, 0],
						rotate: [0, -50, 0],
						scale: 0.85,
					},
				},
			},
		},
	});

	const testimonialsSwiper = new Swiper(".testimonials-slider", {
		slidesPerView: 1,
		spaceBetween: 0,
		threshold: 10,
		speed: 600,
		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		effect: "creative",
		grabCursor: true,
		creativeEffect: {
			limitProgress: 3,
			prev: {
				translate: ["-88%", 0, 0],
				rotate: [0, 50, 0],
				opacity: 0,
				scale: 0.9,
			},
			next: {
				translate: ["88%", 0, 0],
				rotate: [0, -50, 0],
				opacity: 0,
				scale: 0.9,
			},
		},
		breakpoints: {
			768: {
				creativeEffect: {
					limitProgress: 3,
					prev: {
						translate: ["-93%", 0, 0],
						rotate: [0, 50, 0],
						opacity: 0,
						scale: 0.85,
					},
					next: {
						translate: ["93%", 0, 0],
						rotate: [0, -50, 0],
						opacity: 0,
						scale: 0.85,
					},
				},
			},
		},
		navigation: {
			prevEl: ".testimonials-slider-buttons.slider-buttons .slider-btn-prev",
			nextEl: ".testimonials-slider-buttons.slider-buttons .slider-btn-next",
		},
		/*thumbs: {
			swiper: decorTestimonialsSwiper,
		},*/
	});

	decorTestimonialsSwiper.controller.control = testimonialsSwiper;
	testimonialsSwiper.controller.control = decorTestimonialsSwiper;
}

const clientsSlider = document.getElementsByClassName("clients-slider")[0];
if (clientsSlider) {
	if (window.innerWidth <= 901) {
		const clientsSwiper = new Swiper(".clients-slider", {
			slidesPerView: 1,
			spaceBetween: 16,
			threshold: 10,
			loop: true,
			loopedSlides: 12,
			autoplay: {
				delay: 3000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			navigation: {
				prevEl: ".clients-slider-buttons.slider-buttons .slider-btn-prev",
				nextEl: ".clients-slider-buttons.slider-buttons .slider-btn-next",
			},
			breakpoints: {
				421: {
					slidesPerView: 2,
					spaceBetween: 16,
				},
				768: {
					slidesPerView: 3,
					spaceBetween: 16,
				},
			},
		});
	}
}

if (document.getElementById("typed")) {
	let typed = new Typed("#typed", {
		stringsElement: "#typed-strings",
		typeSpeed: 150,
		startDelay: 570,
		backSpeed: 50,
		loop: true,
	});
}

const contactTyping = document.querySelector(".label-text.text-typing");
if (contactTyping) {
	const contactTypingInput = document.querySelector(
		".contact-input.with-typing input"
	);
	contactTypingInput.addEventListener("blur", () => {
		if (contactTypingInput.value === "") {
			contactTyping.classList.remove("hidden-typed");
			contactTypingInput.classList.remove("active");
		}
	});

	contactTypingInput.addEventListener("click", () => {
		contactTyping.classList.add("hidden-typed");
		contactTypingInput.classList.add("active");
	});
}

const solutionsAccordion = document.getElementsByClassName(
	"solutions-accordion"
)[0];
if (solutionsAccordion) {
	const accordionBtn = solutionsAccordion.querySelectorAll(
		".solutions-accordion__btn"
	);
	const accordionItem = solutionsAccordion.querySelectorAll(
		".solutions-accordion__item"
	);
	solutionsAccordion.style.minHeight = `${solutionsAccordion.clientHeight}px`;

	accordionBtn.forEach((btn, i) => {
		btn.addEventListener("click", () => {
			const isActive = btn.classList.contains("active");

			const activeBtn = solutionsAccordion.querySelector(
				".solutions-accordion__btn.active"
			);
			const activeItem = solutionsAccordion.querySelector(
				".solutions-accordion__item.active"
			);

			if (activeBtn) {
				activeBtn.classList.remove("active");
				activeItem.classList.remove("active");
			}

			if (isActive) return;

			btn.classList.add("active");
			accordionItem[i].classList.add("active");
		});
	});
}

const areasContainer = document.getElementsByClassName("areas-container")[0];
if (areasContainer) {
	const areasArr = document.querySelectorAll(".areas-container");
	areasArr.forEach(areas => {
		const tabBtn = areas.querySelectorAll(".areas-tab__btn");
		const tabItem = areas.querySelectorAll(".areas-tab__item");
		const contentBtn = areas.querySelectorAll(".areas-content__btn");
		const contentItem = areas.querySelectorAll(".areas-content__item");
		/*solutionsAccordion.style.minHeight = `${solutionsAccordion.clientHeight}px`;*/

		let prevIndex = 0;

		tabBtn.forEach((btn, i) => {
			btn.addEventListener("click", () => {
				const isActive = btn.classList.contains("active");

				const activeTabBtn = areas.querySelector(".areas-tab__btn.active");
				const activeTabItem = areas.querySelector(".areas-tab__item.active");
				const activeContentBtn = areas.querySelector(
						".areas-content__btn.active"
				);
				const activeContentItem = areas.querySelector(
						".areas-content__item.active"
				);

				for (let j = 0; j < tabBtn.length; j++) {
					if (tabBtn[j].classList.contains("active")) {
						prevIndex = j;
					}
				}

				if (activeTabBtn) {
					activeTabBtn.classList.remove("active");
					activeTabItem.classList.remove("active");
					activeContentBtn.classList.remove("active");
					activeContentItem.classList.remove("active");
				}

				if (isActive) return;

				btn.classList.add("active");
				contentBtn[i].classList.add("active");
				tabItem[i].classList.add("active");
				contentItem[i].classList.add("active");
				console.log(prevIndex);
			});
		});

		contentBtn.forEach((btn, i) => {
			btn.addEventListener("click", () => {
				btn.classList.remove("active");
				tabBtn[i].classList.remove("active");
				tabItem[i].classList.remove("active");
				contentItem[i].classList.remove("active");

				contentBtn[prevIndex].classList.add("active");
				tabBtn[prevIndex].classList.add("active");
				tabItem[prevIndex].classList.add("active");
				contentItem[prevIndex].classList.add("active");
			});
		});
	})
}

const submitSection = document.getElementsByClassName("submit-section")[0];
if (submitSection) {
	const submitTopItems = submitSection.querySelectorAll(
		".submit-top-container__item"
	);
	const submitTabButtons = submitSection.querySelectorAll(".submit-tab-btn");
	const submitForms = submitSection.querySelectorAll(".submit-form");

	submitTabButtons.forEach((btn, i) => {
		btn.addEventListener("click", () => {
			const isActive = btn.classList.contains("active");
			if (isActive) return;
			submitSection
				.querySelector(".submit-top-container__item.active")
				.classList.remove("active");
			submitSection
				.querySelector(".submit-tab-btn.active")
				.classList.remove("active");
			submitSection
				.querySelector(".submit-form.active")
				.classList.remove("active");

			btn.classList.add("active");
			submitForms[i].classList.add("active");
			submitTopItems[i].classList.add("active");
		});
	});
}

const choicesDOM = document.querySelector(["[data-choices]"]);
if (choicesDOM) {
	const choicesArr = document.querySelectorAll(["[data-choices]"]);
	for (let i = 0; i < choicesArr.length; i++) {
		new Choices(choicesArr[i], {
			searchEnabled: true,
			itemSelectText: "",
			placeholder: true,
			classNames: {
				flippedState: "",
			},
		});
	}
}

const doubleRangeSliderInputs = () => {
	const doubleRange = document.getElementsByClassName("double-range-inputs")[0];
	if (doubleRange) {
		const slider = doubleRange.querySelector("#double-range-inputs");
		const max = +slider.dataset.max;
		const min = +slider.dataset.min;
		const step = +slider.dataset.step;
		const inputs = doubleRange.querySelectorAll(".double-range-input");
		const startValueMin = +inputs[0].value.replace(/[$,]/g, "");
		const startValueMax = +inputs[1].value.replace(/[$,]/g, "");

		noUiSlider.create(slider, {
			start: [startValueMin, startValueMax],
			connect: true,
			margin: 10,
			step: step,
			range: {
				min: min,
				max: max,
			},
		});

		slider.noUiSlider.on("update", function (values, handle) {
			inputs[handle].value = Math.round(values[handle]).toLocaleString(
				"en-GB",
				{
					style: "currency",
					currency: "GBP",
					minimumFractionDigits: 0,
				}
			);
		});

		inputs.forEach((elem, i) => {
			elem.addEventListener("input", function () {
				let value = elem.value.replace(/[$,]/g, "");
				slider.noUiSlider.set(value);
			});
		});

		slider.noUiSlider.on("end", function () {
			load('jobs/search', 'form:#search_form', 'form:#search_form2');
			return false;
		});
	}
};
doubleRangeSliderInputs();

const singleRangeSlider = () => {
	const singleRange = document.getElementsByClassName(
		"single-range-default"
	)[0];
	if (singleRange) {
		const slider = singleRange.querySelector("#single-range-default");
		const max = +slider.dataset.max;
		const min = +slider.dataset.min;
		const unit = slider.dataset?.unit || "£";
		const step = +slider.dataset.step;
		const inputsHidden = singleRange.querySelector(
			".single-range-hidden-input"
		);
		const inputInfo = singleRange.querySelector(".single-range-info");
		const startValue = +inputsHidden.value;

		noUiSlider.create(slider, {
			start: [startValue],
			connect: "lower",
			margin: 10,
			step: step,
			range: {
				min: min,
				max: max,
			},
		});

		slider.noUiSlider.on("update", function (values, handle) {
			inputInfo.textContent = `${Math.round(values[handle])} ${unit}`;

			inputsHidden.value = Math.round(values[handle]);
		});
	}
};
singleRangeSlider();

const btnShowHide = document.getElementsByClassName("btn-show-hide")[0];
if (btnShowHide) {
	const btnShowHideArr = document.querySelectorAll(".btn-show-hide");
	const showHideContentArr = document.querySelectorAll(".show-hide-content");
	showHideContentArr.forEach((content, i) => {
		const items = content.querySelectorAll("li");
		items.forEach((item, index) => {
			if (index > 4) {
				item.classList.add("is-hidden");
			}
		});
		if (items.length > 5) {
			btnShowHideArr[i].classList.add("is-visible");

			btnShowHideArr[i].addEventListener("click", () => {
				btnShowHideArr[i].classList.toggle("active");
				items.forEach((item, index) => {
					if (index > 4) {
						item.classList.toggle("is-hidden");
					}
				});
			});
		}
	});
}

const contentSticky = document.getElementById("content-sticky");
const rightSidebar = document.getElementById("sidebar");
if (rightSidebar) {
	const sidebarBuffer = rightSidebar.querySelector(".sidebar__buffer");
	const sidebarInner = rightSidebar.querySelector(".sidebar__inner");
	// const headerHeight = +getRootStyle("--header-height").replace("px", "");
	const headerHeight = 0;

	// gap on top and bottom of sticky sidebar
	const gap = 30;

	let lastScrollTop = 0;

	// if flag true === scrolling down else scrolling up
	let flag = null;

	// if flagHeight true === sidebar is higher than viewport height
	let flagHeight =
		document.querySelector(".sidebar__inner").getBoundingClientRect().height >
		window.innerHeight - headerHeight;

	function calcHeightOfSidebar(flag) {
		const contentStickyTop = getPosition(contentSticky).y;
		const sidebarInnerTop = getPosition(sidebarInner).y;
		sidebarBuffer.style.height = `${sidebarInnerTop - contentStickyTop}px`;

		if (flag) {
			// scroll up
			sidebarInner.style.top = "";
			sidebarInner.style.bottom = `${
				-headerHeight -
				gap +
				window.innerHeight -
				sidebarInner.getBoundingClientRect().height
			}px`;
		} else {
			// scroll down
			sidebarInner.style.bottom = "";
			sidebarInner.style.top = `${
				Math.min(
					window.innerHeight - sidebarInner.getBoundingClientRect().height,
					headerHeight
				) - gap
			}px`;
		}
		flagHeight =
			sidebarInner.getBoundingClientRect().height >
			window.innerHeight - headerHeight;
	}

	function resetSticky() {
		sidebarInner.style.bottom = "";
		sidebarInner.style.top = `${headerHeight + gap}px`;
		sidebarBuffer.style.height = "0";
	}

	if (!flagHeight) {
		resetSticky();
	} else {
		calcHeightOfSidebar(false);
	}

	window.addEventListener("scroll", function () {
		let st = window.pageYOffset || document.documentElement.scrollTop;

		if (!flagHeight) {
			resetSticky();
		}

		if (st > lastScrollTop && !flag) {
			// scroll down
			calcHeightOfSidebar(flag);
			flag = true;
		} else if (st < lastScrollTop && flag) {
			// scroll up
			calcHeightOfSidebar(flag);
			flag = false;
		}
		lastScrollTop = st <= 0 ? 0 : st;
	});

	window.addEventListener("resize", function (e) {
		if (!flagHeight) {
			resetSticky();
		}

		calcHeightOfSidebar(true);
		calcHeightOfSidebar(false);
	});
}

const btnFilters = document.getElementsByClassName("btn-filters")[0];
if (btnFilters) {
	const btnClose = document.querySelector('.btn-filter-close');
	const sidebar = document.querySelector(".sidebar");
	const jobsList = document.querySelector(".jobs-list");
	const jobsSearchContainer = document.querySelector(
		".jobs-search-section__wrap"
	);
	/*const paginationSectionJobs = document.querySelector(
		".result-section.jobs-page"
	);*/

	btnFilters.addEventListener("click", () => {
		btnFilters.classList.toggle("active");
		btnClose.classList.toggle("active");
		sidebar.classList.toggle("active");
		jobsList.classList.toggle("active");
		jobsSearchContainer.classList.toggle("active");
		/*paginationSectionJobs.classList.toggle("active");*/
	});

	btnClose.addEventListener("click", () => {
		btnFilters.classList.toggle("active");
		btnClose.classList.toggle("active");
		sidebar.classList.toggle("active");
		jobsList.classList.toggle("active");
		jobsSearchContainer.classList.toggle("active");
		/*paginationSectionJobs.classList.toggle("active");*/
	});
}

if(document.getElementsByClassName('default-file-upload')[0]){
	const fileInputsContainerArr = document.querySelectorAll('.default-file-upload');
	fileInputsContainerArr.forEach((elem, i) => {
		const input = elem.querySelector('.default-file-upload__input');
		const hiddenInput = elem.querySelector('.default-file-upload__hidden-input');
		input.addEventListener('change', (event) => {
			const files = event.target.files;

			if (files.length > 0) {
				elem.classList.add('active');
			} else {
				elem.classList.remove('active');
			}
		})
	})
}

if(document.getElementsByClassName('checkbox-contact-btn')[0]) {
	const checkboxContactBtn = document.querySelectorAll('.checkbox-contact-btn');
	const briefFileInput = document.querySelector('.brief-file-input');
	checkboxContactBtn.forEach((checkbox, i) => {
		const input = checkbox.querySelector('input');
		const span = checkbox.querySelector('span');

		let isActive = false;

		input.addEventListener('change', () => {
			if(input.checked && span.textContent === 'Submit a brief') {
				briefFileInput.classList.add('active');
				isActive = true;
			} else {
				briefFileInput.classList.remove('active');
			}
		})

		if(input.checked && span.textContent === 'Submit a brief') {
			briefFileInput.classList.add('active');
			isActive = true;
		}
	})
}

var scroll = new SmoothScroll('a[href*="#"]', {
	/*speed: 200*/
});

if(document.querySelector('.social-btn[href^="tel:"]')) {
	const links = document.querySelectorAll('.social-btn[href^="tel:"]');

	links.forEach(link => {
		const hrefValue = link.getAttribute('href');
		const phoneNumber = hrefValue.replace('tel:', '');
		link.setAttribute('data-phone', phoneNumber);
	});
}


window.closePopup = function closePopup() {
	document.querySelector("#popup").classList.remove("active");
	scrollLock.clearQueueScrollLocks();
	scrollLock.enablePageScroll();
};

$("a").attr("title", "");
